// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-automobile-jsx": () => import("./../../../src/pages/industries/automobile.jsx" /* webpackChunkName: "component---src-pages-industries-automobile-jsx" */),
  "component---src-pages-industries-education-jsx": () => import("./../../../src/pages/industries/education.jsx" /* webpackChunkName: "component---src-pages-industries-education-jsx" */),
  "component---src-pages-industries-enterprise-jsx": () => import("./../../../src/pages/industries/enterprise.jsx" /* webpackChunkName: "component---src-pages-industries-enterprise-jsx" */),
  "component---src-pages-industries-event-jsx": () => import("./../../../src/pages/industries/event.jsx" /* webpackChunkName: "component---src-pages-industries-event-jsx" */),
  "component---src-pages-industries-fintech-jsx": () => import("./../../../src/pages/industries/fintech.jsx" /* webpackChunkName: "component---src-pages-industries-fintech-jsx" */),
  "component---src-pages-industries-health-care-jsx": () => import("./../../../src/pages/industries/health-care.jsx" /* webpackChunkName: "component---src-pages-industries-health-care-jsx" */),
  "component---src-pages-industries-jsx": () => import("./../../../src/pages/industries.jsx" /* webpackChunkName: "component---src-pages-industries-jsx" */),
  "component---src-pages-industries-media-and-entertainment-jsx": () => import("./../../../src/pages/industries/media-and-entertainment.jsx" /* webpackChunkName: "component---src-pages-industries-media-and-entertainment-jsx" */),
  "component---src-pages-industries-on-demand-jsx": () => import("./../../../src/pages/industries/on-demand.jsx" /* webpackChunkName: "component---src-pages-industries-on-demand-jsx" */),
  "component---src-pages-industries-real-estate-jsx": () => import("./../../../src/pages/industries/real-estate.jsx" /* webpackChunkName: "component---src-pages-industries-real-estate-jsx" */),
  "component---src-pages-industries-saas-jsx": () => import("./../../../src/pages/industries/saas.jsx" /* webpackChunkName: "component---src-pages-industries-saas-jsx" */),
  "component---src-pages-industries-social-media-jsx": () => import("./../../../src/pages/industries/social-media.jsx" /* webpackChunkName: "component---src-pages-industries-social-media-jsx" */),
  "component---src-pages-industries-travel-jsx": () => import("./../../../src/pages/industries/travel.jsx" /* webpackChunkName: "component---src-pages-industries-travel-jsx" */),
  "component---src-pages-industries-virtual-reality-jsx": () => import("./../../../src/pages/industries/virtual-reality.jsx" /* webpackChunkName: "component---src-pages-industries-virtual-reality-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-process-jsx": () => import("./../../../src/pages/process.jsx" /* webpackChunkName: "component---src-pages-process-jsx" */),
  "component---src-pages-services-android-app-js": () => import("./../../../src/pages/services/android-app.js" /* webpackChunkName: "component---src-pages-services-android-app-js" */),
  "component---src-pages-services-block-chain-js": () => import("./../../../src/pages/services/block-chain.js" /* webpackChunkName: "component---src-pages-services-block-chain-js" */),
  "component---src-pages-services-cross-platform-js": () => import("./../../../src/pages/services/cross-platform.js" /* webpackChunkName: "component---src-pages-services-cross-platform-js" */),
  "component---src-pages-services-data-visualization-js": () => import("./../../../src/pages/services/data-visualization.js" /* webpackChunkName: "component---src-pages-services-data-visualization-js" */),
  "component---src-pages-services-e-commerce-js": () => import("./../../../src/pages/services/e-commerce.js" /* webpackChunkName: "component---src-pages-services-e-commerce-js" */),
  "component---src-pages-services-enterprise-solution-js": () => import("./../../../src/pages/services/enterprise-solution.js" /* webpackChunkName: "component---src-pages-services-enterprise-solution-js" */),
  "component---src-pages-services-ios-development-js": () => import("./../../../src/pages/services/ios-development.js" /* webpackChunkName: "component---src-pages-services-ios-development-js" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-mobile-first-js": () => import("./../../../src/pages/services/mobile-first.js" /* webpackChunkName: "component---src-pages-services-mobile-first-js" */),
  "component---src-pages-services-qa-service-js": () => import("./../../../src/pages/services/qa-service.js" /* webpackChunkName: "component---src-pages-services-qa-service-js" */),
  "component---src-pages-services-react-n-react-native-js": () => import("./../../../src/pages/services/react-n-react-native.js" /* webpackChunkName: "component---src-pages-services-react-n-react-native-js" */),
  "component---src-pages-services-tech-stack-js": () => import("./../../../src/pages/services/tech-stack.js" /* webpackChunkName: "component---src-pages-services-tech-stack-js" */),
  "component---src-pages-services-wearable-tech-js": () => import("./../../../src/pages/services/wearable-tech.js" /* webpackChunkName: "component---src-pages-services-wearable-tech-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-technologies-angular-jsx": () => import("./../../../src/pages/technologies/angular.jsx" /* webpackChunkName: "component---src-pages-technologies-angular-jsx" */),
  "component---src-pages-technologies-aws-jsx": () => import("./../../../src/pages/technologies/aws.jsx" /* webpackChunkName: "component---src-pages-technologies-aws-jsx" */),
  "component---src-pages-technologies-django-jsx": () => import("./../../../src/pages/technologies/django.jsx" /* webpackChunkName: "component---src-pages-technologies-django-jsx" */),
  "component---src-pages-technologies-docker-jsx": () => import("./../../../src/pages/technologies/docker.jsx" /* webpackChunkName: "component---src-pages-technologies-docker-jsx" */),
  "component---src-pages-technologies-elastic-search-jsx": () => import("./../../../src/pages/technologies/elastic-search.jsx" /* webpackChunkName: "component---src-pages-technologies-elastic-search-jsx" */),
  "component---src-pages-technologies-express-js-jsx": () => import("./../../../src/pages/technologies/express-js.jsx" /* webpackChunkName: "component---src-pages-technologies-express-js-jsx" */),
  "component---src-pages-technologies-gatsby-js-jsx": () => import("./../../../src/pages/technologies/gatsby-js.jsx" /* webpackChunkName: "component---src-pages-technologies-gatsby-js-jsx" */),
  "component---src-pages-technologies-google-cloud-jsx": () => import("./../../../src/pages/technologies/google-cloud.jsx" /* webpackChunkName: "component---src-pages-technologies-google-cloud-jsx" */),
  "component---src-pages-technologies-java-jsx": () => import("./../../../src/pages/technologies/java.jsx" /* webpackChunkName: "component---src-pages-technologies-java-jsx" */),
  "component---src-pages-technologies-javascript-jsx": () => import("./../../../src/pages/technologies/javascript.jsx" /* webpackChunkName: "component---src-pages-technologies-javascript-jsx" */),
  "component---src-pages-technologies-jsx": () => import("./../../../src/pages/technologies.jsx" /* webpackChunkName: "component---src-pages-technologies-jsx" */),
  "component---src-pages-technologies-laravel-jsx": () => import("./../../../src/pages/technologies/laravel.jsx" /* webpackChunkName: "component---src-pages-technologies-laravel-jsx" */),
  "component---src-pages-technologies-mongodb-jsx": () => import("./../../../src/pages/technologies/mongodb.jsx" /* webpackChunkName: "component---src-pages-technologies-mongodb-jsx" */),
  "component---src-pages-technologies-mysql-jsx": () => import("./../../../src/pages/technologies/mysql.jsx" /* webpackChunkName: "component---src-pages-technologies-mysql-jsx" */),
  "component---src-pages-technologies-node-jsx": () => import("./../../../src/pages/technologies/node.jsx" /* webpackChunkName: "component---src-pages-technologies-node-jsx" */),
  "component---src-pages-technologies-postgre-jsx": () => import("./../../../src/pages/technologies/postgre.jsx" /* webpackChunkName: "component---src-pages-technologies-postgre-jsx" */),
  "component---src-pages-technologies-react-jsx": () => import("./../../../src/pages/technologies/react.jsx" /* webpackChunkName: "component---src-pages-technologies-react-jsx" */),
  "component---src-pages-technologies-react-native-jsx": () => import("./../../../src/pages/technologies/react-native.jsx" /* webpackChunkName: "component---src-pages-technologies-react-native-jsx" */),
  "component---src-pages-technologies-shopify-jsx": () => import("./../../../src/pages/technologies/shopify.jsx" /* webpackChunkName: "component---src-pages-technologies-shopify-jsx" */),
  "component---src-pages-technologies-swift-objective-c-jsx": () => import("./../../../src/pages/technologies/swift-objectiveC.jsx" /* webpackChunkName: "component---src-pages-technologies-swift-objective-c-jsx" */),
  "component---src-pages-technologies-woocommerce-jsx": () => import("./../../../src/pages/technologies/woocommerce.jsx" /* webpackChunkName: "component---src-pages-technologies-woocommerce-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

